import _isEmpty from 'lodash/isEmpty';

import { getInterestRates } from '@atc/bonnet-reference';

import { fallbackInterestRates, subPrimeInterestRates } from 'axl-config';

import { interestRateDuck } from '@/ducks';

const isValidRatesResponse = (fetchedRates) => {
    const baseError = 'Error fetching interest rates...';
    const requiredCreditRanges = ['excellent', 'verygood', 'good', 'fair', 'rebuilding'];
    const requiredLoanTerms = [36, 48, 60, 72];
    if (!fetchedRates || _isEmpty(fetchedRates)) {
        // eslint-disable-next-line no-console
        console.error(`${baseError} The response was empty...`);
        return false;
    }
    for (const requiredCreditRange of requiredCreditRanges) {
        // Verify required credit ranges
        if (!{}.hasOwnProperty.call(fetchedRates, requiredCreditRange)) {
            // eslint-disable-next-line no-console
            console.error(`${baseError} The ${requiredCreditRange} credit range was missing from the response...`);
            return false;
        }
        const ratesByCreditRange = fetchedRates[requiredCreditRange].rates;
        for (const requiredLoanTerm of requiredLoanTerms) {
            // Verify required loan terms
            if (!{}.hasOwnProperty.call(ratesByCreditRange, requiredLoanTerm)) {
                // eslint-disable-next-line no-console
                console.error(`${baseError} The ${requiredLoanTerm} loan term was missing from the response for the ${requiredCreditRange} credit range...`);
                return false;
            }
            const ratesByLoanTerm = ratesByCreditRange[requiredLoanTerm];
            // Verify new and used rates are present
            if (!{}.hasOwnProperty.call(ratesByLoanTerm, 'used') || ratesByLoanTerm.used === null || ratesByLoanTerm.used === undefined) {
                // eslint-disable-next-line no-console
                console.error(`${baseError} The rate for used cars was missing from the response for the ${requiredLoanTerm} loan term for the ${requiredCreditRange} credit range...`);
                return false;
            }
            if (!{}.hasOwnProperty.call(ratesByLoanTerm, 'new') || ratesByLoanTerm.new === null || ratesByLoanTerm.new === undefined) {
                // eslint-disable-next-line no-console
                console.error(`${baseError} The rate for new cars was missing from the response for the ${requiredLoanTerm} loan term for the ${requiredCreditRange} credit range...`);
                return false;
            }
        }
    }
    return true;
};

export default function withInterestRates() {
    return async (ctx) => {
        const {
            enable_subprime_credit: [enableSubprimeCredit],
        } = ctx.useFeatures(['enable_subprime_credit']);

        const { brand } = ctx.data;

        // Only run on initial page load
        if (typeof window === 'undefined') {
            let rates;
            try {
                rates = await getInterestRates();
                if (enableSubprimeCredit && brand === 'atc') {
                    rates = { ...rates, ...subPrimeInterestRates };
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching interest rates: ', error);
            }

            if (isValidRatesResponse(rates)) {
                rates.isFallback = false;
                await ctx.store.dispatch(interestRateDuck.creators.setKey(rates));
            } else {
                // If no rates or rates object is empty set fallbacks
                await ctx.store.dispatch(interestRateDuck.creators.setKey(fallbackInterestRates));
            }
        }
    };
}
