import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { vdpDuckPreset } from '@/ducks/presets';

// TODO: combine activeEmailListing here too
export default objectDuckCreator({
    ...vdpDuckPreset,
    store: 'activeInteraction',
    initialState: {
        emailCTAContext: '',
        showCompareModal: false,
        showFinanceAppModal: false,
        showEmailModal: false,
        displayHybridVdp: false,
        showEmailConfirmationModal: false,
        showKBBDealerModal: false,
        showPsxExplainerContent: '',
    },
}).extend({
    selectors: {
        getEmailCTAContext: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).emailCTAContext),
        getShowCompareModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showCompareModal),
        getDisplayHybridVdp: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).displayHybridVdp),
        getShowEmailModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showEmailModal),
        getShowEmailConfirmationModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showEmailConfirmationModal),
        getShowKBBDealerModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showKBBDealerModal),
        getShowFinanceAppModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showFinanceAppModal),
        getShowPsxExplainerContent: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showPsxExplainerContent),
    },
});
