import _get from 'lodash/get';

import {
    applyValueToWalletClick,
    browseByBudgetWalletContinueClick,
    carAlertsClick,
    compareListingsClick,
    compareListingSendOffer,
    compareListingsModalPageView,
    compareToggleClick,
    contactDealerClick,
    creditPreApprovalClick,
    dealerRatingClick,
    dealerRatingImpressions,
    editDealerOfferCTAClick,
    editThisOfferClick,
    footerLinkClick,
    getPayoffQuoteFromLenderClick,
    getPayoffQuoteSubmitClick,
    headerGeneralEventHandler,
    messagesHeaderLinkClick,
    myDealSeeDetailsClick,
    myWalletCalculatePaymentsCTAClick,
    myWalletCarouselBudgetClick,
    myWalletCheckDealStatusCTAClick,
    myWalletEngagementClick,
    myWalletFilterToggleClick,
    myWalletGridInlineClick,
    myWalletGridTradeInIndicator,
    myWalletIcoLinkClick,
    myWalletIconButtonClick,
    myWalletInputChange,
    myWalletInteractionClick,
    myWalletKbbTradeInIndicator,
    myWalletMobileToolbarClick,
    myWalletMonthlyPaymentFilterClick,
    myWalletNonDealNotificationsClick,
    myWalletNonDealNotificationsImpression,
    myWalletOpenPageLoad,
    myWalletReturnToVdpCtaClick,
    myWalletSearchAvailableVehiclesSubmit,
    myWalletSearchByBudgetClick,
    myWalletSellYourCarClick,
    myWalletSrpPlacementClick,
    myWalletSummaryLinkClick,
    myWalletTradeInClickLink,
    myWalletTradeInSubmit,
    myWalletTradeInValueAnotherVehicleClick,
    offsiteBrowserPushNotificationClick,
    offsiteBrowserPushNotificationImpression,
    paymentDetailsMakeChangesClick,
    paymentDetailsVehicleCTAClick,
    paymentOptionClick,
    searchAlertsClick,
    searchAvailableVehiclesClick,
    seeDetailsDealClick,
    seeDetailsGlobalNavDealClick,
    seeDetailsGlobalNavDealImpression,
    seeDetailsMyWalletDealClick,
    snap360Click,
    telMetricsPhoneTextClick,
    testDriveScheduleClick,
    testDriveSubmit,
    textLinkDealClick,
    valueYourVehicleClick,
    verifyCreditRangeClick,
    verifyCreditRangeMessageImpression,
    walletAutoSyncSuccess,
    walletEmailCaptureSubmit,
    walletImportSettingsClick,
    walletManualImportBtnClick,
} from 'reaxl-analytics';

import {
    paymentsDuck,
    userDuck,
} from '@/ducks';

import { srpFiltersDuck } from '@/ducks/srp';
import { vdpResultsDuck } from '@/ducks/vdp';

export default {
    compareListingsClick: (metadata = {}, option) => {
        const { data, state } = metadata;

        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);

        return compareListingsClick({
            ...metadata,
            data: {
                ...data,
                pixallData: {
                    ...data.pixallData,
                    filterValues,
                },
            },
        }, option);
    },

    browseByBudgetWalletContinueClick,

    dealerRatingClick,

    dealerRatingImpressions,

    myWalletOpenPageLoad,

    myWalletFilterToggleClick: (metadata = {}, option) => myWalletFilterToggleClick({
        ...metadata,
    }, option),

    myWalletMonthlyPaymentFilterClick,

    myWalletSearchByBudgetClick,

    myWalletSummaryLinkClick,

    myWalletCalculatePaymentsCTAClick: (metadata, option) => myWalletCalculatePaymentsCTAClick({
        ...metadata,
        userDuck,
    }, option),

    myWalletTradeInClickLink,

    messagesHeaderLinkClick,

    myWalletTradeInSubmit,

    myWalletIcoLinkClick: (metadata, option) => myWalletIcoLinkClick({
        ...metadata,
        paymentsDuck,
    }, option),

    myWalletIconButtonClick,

    myWalletInputChange,

    myWalletInteractionClick: (metadata, option) => myWalletInteractionClick({ ...metadata, paymentsDuck }, option),

    myWalletReturnToVdpCtaClick,

    myWalletSearchAvailableVehiclesSubmit,

    myWalletMobileToolbarClick,

    myWalletCarouselBudgetClick,

    myWalletCheckDealStatusCTAClick,

    myWalletNonDealNotificationsImpression,

    myWalletNonDealNotificationsClick,

    paymentDetailsMakeChangesClick,

    paymentDetailsVehicleCTAClick,

    applyValueToWalletClick,

    myWalletKbbTradeInIndicator,

    carAlertsClick: (metadata, ...arg) => carAlertsClick({
        ...metadata,
    }, ...arg),

    searchAlertsClick: (metadata, ...arg) => searchAlertsClick({
        ...metadata,
    }, ...arg),

    compareListingsModalPageView: (metadata, ...arg) => {
        const { data, state } = metadata;

        const { pixallData } = data;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        const zipCode = _get(userDuck.selectors.getZip(state));

        return compareListingsModalPageView({
            ...metadata,
            data: {
                ...data,
                pixallData: {
                    ...pixallData,
                    filterValues,
                    zipCode,
                },
            },
        }, ...arg);
    },

    verifyCreditRangeClick,

    verifyCreditRangeMessageImpression,

    myWalletGridInlineClick,
    myWalletGridTradeInIndicator,

    myWalletSrpPlacementClick,
    contactDealerClick,
    seeDetailsDealClick,
    editDealerOfferCTAClick,
    editThisOfferClick,
    textLinkDealClick,
    testDriveSubmit,
    testDriveScheduleClick,
    creditPreApprovalClick,
    seeDetailsMyWalletDealClick,
    seeDetailsGlobalNavDealImpression,
    seeDetailsGlobalNavDealClick,
    telMetricsPhoneTextClick,
    walletAutoSyncSuccess,
    walletImportSettingsClick,
    walletManualImportBtnClick,
    paymentOptionClick,
    compareToggleClick,
    getPayoffQuoteFromLenderClick,
    getPayoffQuoteSubmitClick,
    compareListingSendOffer,

    headerGeneralEventHandler: (metadata = {}, option) => {
        const { data, state } = metadata;

        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return headerGeneralEventHandler({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
            },
        }, option);
    },
    footerLinkClick: (metadata = {}, option) => footerLinkClick({
        ...metadata,
    }, option),
    myWalletEngagementClick: (metadata, option) => myWalletEngagementClick({
        ...metadata,
        paymentsDuck,
    }, option),
    myWalletSellYourCarClick: (metadata, option) => myWalletSellYourCarClick({
        ...metadata,
        paymentsDuck,
    }, option),
    offsiteBrowserPushNotificationClick: (metadata, option) => {
        const { data, state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);

        return offsiteBrowserPushNotificationClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
                filterValues,
            },
            paymentsDuck,
        }, option);
    },
    offsiteBrowserPushNotificationImpression: (metadata, option) => {
        const { data, state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return offsiteBrowserPushNotificationImpression({
            ...metadata,
            data: {
                ...data,
                filterValues,
                inventoryId: activeInventory.id,
            },
            paymentsDuck,
        }, option);
    },
    myDealSeeDetailsClick: (metadata = {}, option) => myDealSeeDetailsClick({
        ...metadata,
        paymentsDuck,
    }, option),
    searchAvailableVehiclesClick: (metadata = {}, option) => searchAvailableVehiclesClick({
        ...metadata,
        paymentsDuck,
    }, option),
    myWalletTradeInValueAnotherVehicleClick,
    valueYourVehicleClick,
    walletEmailCaptureSubmit,
    snap360Click: (metadata = {}, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return snap360Click({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
            },
        }, option);
    },
};
