import { createSelector, DuckSelector } from '@atc/modular-redux';

import { resultsDuckCreator } from 'axl-ducks';

import { srpDuckPreset } from '@/ducks/presets';

const srpResultsDuck = resultsDuckCreator({
    ...srpDuckPreset,
}).extend({
    selectors: (duck) => ({
        getFourthSpotlightTier: new DuckSelector((selectors) => createSelector(
            selectors.getActiveInventory,
            (listings = []) => {

                if (listings.length > 19) {
                    const _listings = listings.slice(0, 25);

                    const { featured, standard } = _listings.reduce((counter, { priority = '' }) => {
                        if (priority === '10') {
                            counter.standard += 1;
                        } else if (priority === '20') {
                            counter.featured += 1;
                        }
                        return counter;
                    }, { featured: 0, standard: 0 });

                    if (featured > 0 || standard > 0) {
                        return featured >= standard ? 'Featured' : 'Standard';
                    }
                    return false;
                }
                return false;
            }
        )),
        getInitialLoad: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).initialLoad),
    }),
});

export default srpResultsDuck;
